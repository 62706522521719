import React from 'react';

import s from './SectionTitle.scss';

interface SectionTitleProps {
  en?: string;
  jp?: string;
}

export const SectionTitle = ({ en, jp }: SectionTitleProps) => (
  <div className={s.column}>
    <h2 className={s.column__en}>{en}</h2>
    <h2 className={s.column__jp}>{jp}</h2>
  </div>
);
