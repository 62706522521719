import React, { ReactNode } from 'react';

import { Link } from 'components/link/Link';

import s from './Button.scss';

interface ButtonRedProps {
  children: ReactNode;
  href?: string;
  onClick?(): void;
  responsive?: ReactNode;
}

export const ButtonRed = ({ children, href, onClick, responsive }: ButtonRedProps) => {
  const isLink = typeof href !== 'undefined';
  const isExternal = isLink && /^((https?:)?\/\/|[0-9a-zA-Z]+:)/.test(href || '');

  if (isExternal) {
    return (
      <a className={s(s.button__red, responsive ? "responsive" : "")}
        target="_blank" rel="noopener noreferrer" href={href}>
        {children}
      </a >
    );
  }

  if (isLink) {
    return (
      <Link className={s(s.button__red, responsive ? "responsive" : "")}
        to={href || '#'}>
        {children}
      </Link>
    );
  }

  return (
    <button className={s(s.button__red, responsive ? "responsive" : "")} onClick={onClick}>
      {children}
    </button>
  );
};
