import React, { ReactNode } from 'react';

import { Link } from 'components/link/Link';

import Logo from 'assets/svg/logo.svg';

import s from './Header.scss';

interface HeaderProps {
  children: ReactNode;
}

export const Header = ({ children }: HeaderProps) => (
  <header className={s.header}>
    <div className={s.header__inner}>
      <Link to="/" className={s.header__logo}>
        <Logo className={s.header__logoSvg} />
      </Link>

      <div className={s.header__navigation}>{children}</div>
    </div>
  </header>
);
