import React, { Children, ReactNode } from 'react';

import { Container } from 'components/container/Container';
import { SectionTitle } from 'components/title/SectionTitle';
import { ButtonRed } from 'components/button/ButtonRed';
import { ButtonYellow } from 'components/button/ButtonYellow';

import s from './SectionBlock.scss';

interface SectionBlockProps {
  en?: string;
  jp?: string;
  description?: ReactNode;
  buttontext?: string;
  buttonhref?: string;
  green?: ReactNode;
  red?: ReactNode;
  yellow?: ReactNode;
  buttonred?: ReactNode;
  buttonyellow?: ReactNode;
  children?: ReactNode;
}

export const SectionBlock = ({ en, jp, description, buttonhref, buttontext, green, red, yellow, buttonred, buttonyellow, children }: SectionBlockProps) => (
  <div className={s(s.block, green ? "green" : "", red ? "red" : "", yellow ? "yellow" : "")}>
    <Container>
      <div className={s.block__col}>
        <SectionTitle en={en} jp={jp} />
        {description ? <p className={s.block__description}>{description}</p> : ''}
        {buttontext ? <div className={s.block__button}>
          {buttonred ? <ButtonRed href={buttonhref}>{buttontext}</ButtonRed> : ""}
          {buttonyellow ? <ButtonYellow href={buttonhref}>{buttontext}</ButtonYellow> : ""}
        </div> : ''}
        {children}
      </div>
    </Container>
  </div >
);
