import React, { ReactNode } from 'react';

import { Link } from 'components/link/Link';

import s from './Button.scss';

interface ButtonYellowProps {
  children: ReactNode;
  href?: string;
  onClick?(): void;
}

export const ButtonYellow = ({ children, href, onClick }: ButtonYellowProps) => {
  const isLink = typeof href !== 'undefined';
  const isExternal = isLink && /^((https?:)?\/\/|[0-9a-zA-Z]+:)/.test(href || '');

  if (isExternal) {
    return (
      <a className={s.button__yellow} target="_blank" rel="noopener noreferrer" href={href}>
        {children}
      </a >
    );
  }

  if (isLink) {
    return (
      <Link className={s.button__yellow} to={href || '#'}>
        {children}
      </Link>
    );
  }

  return (
    <button className={s.button__yellow} onClick={onClick}>
      {children}
    </button>
  );
};
