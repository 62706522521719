import React, { ReactNode } from "react";
import { Helmet } from "react-helmet";
import { ButtonRed } from "components/button/ButtonRed";

import Logo from "../../assets/svg/logo.svg";

import Facebook from "../../assets/svg/social-facebook.svg";
import LinkedIn from "../../assets/svg/social-linkedin.svg";
import Twitter from "../../assets/svg/social-twitter.svg";
import Wantedly from "../../assets/svg/social-wantedly.svg";

import { helmet } from "../../utils/helmet";
import { Header } from "../header/Header";
import { Footer } from "../footer/Footer";
import { Devtools } from "../devtools/Devtools";

import s from "./BaseLayout.scss";

interface BaseLayoutProps {
  children: ReactNode;
}

const isDev = process.env.NODE_ENV === "development";

// tslint:disable no-default-export
export default ({ children }: BaseLayoutProps) => (
  <div className={s.layout}>
    <Helmet {...helmet} />
    <Header>
      <ButtonRed responsive href="https://discord.com/invite/YGvhbEbVWf">参加する</ButtonRed>
    </Header>

    {children}

    <Footer
      logo={<Logo />}
      social={[
        { icon: <LinkedIn />, to: "https://www.linkedin.com/company/a.c.o./" },
        { icon: <Twitter />, to: "https://twitter.com/aco_tokyo" },
        { icon: <Wantedly />, to: "https://www.wantedly.com/companies/aco/projects" },
        {
          icon: <Facebook />,
          to:
            "https://www.facebook.com/aco.tokyo/",
        },
      ]}
    />

    {isDev && <Devtools />}
  </div>
);
