import React, { ReactNode } from 'react';

import Logo from 'assets/svg/logo-aco.svg';

import s from './Footer.scss';

interface Social {
  icon: ReactNode;
  to: string;
}

interface FooterProps {
  logo: ReactNode;
  social: Social[];
}

export const Footer = ({ social }: FooterProps) => (
  <div className={s.footer}>
    <div className={s.footer__content}>
      <a className={s.footer__content__logo} href="https://aco-tokyo.com/"><Logo /></a>
      <ul className={s.footer__list}>
        {social.map((item) => (
          <li key={item.to} className={s.footer__item}>
            <a href={item.to} target="_blank" rel="noopener noreferrer">
              {item.icon}
            </a>
          </li>
        ))}
      </ul>
    </div>
  </div>
);
